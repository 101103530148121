<template>
  <div :style="backCss">
    <div class="statistics-menu-list">
      <template v-for="(item, index) in copyColumns">
        <a-dropdown
          :trigger="['click']"
          :ref="'statistics-' + index"
          :key="index"
          :style="item.isNum ? '' : 'display: none;'"
          :placement="'topCenter'"
        >
          <div class="statistics">统计<a-icon type="caret-up" /></div>
          <a-menu slot="overlay">
            <a-menu-item v-if="item.isNum" @click="compute('meanNum', item.dataIndex, index, '求和')">
              求和
            </a-menu-item>
            <a-menu-item v-if="item.isNum" @click="compute('sumNum', item.dataIndex, index, '平均')">
              平均
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </div>
    <a-spin :spinning="tableLoading">
      <a-dropdown v-model="rightVisible" :placement="'bottomRight'" :trigger="['contextmenu']">
        <a-menu slot="overlay">
          <a-menu-item v-for="(item, index) in columns" :key="item.dataIndex">
            <a-checkbox v-model="item.checked" @change="checkChange(index)">
              {{ item.title }}
            </a-checkbox>
          </a-menu-item>
        </a-menu>
        <vxe-table
          border="none"
          ref="xTree"
          :row-config="{ isCurrent: true, isHover: true, keyField: 'id' }"
          :column-config="{ resizable: true }"
          @toggle-tree-expand="toggleExpandChangeEvent"
          :tree-config="treeConfig"
          @checkbox-change="selectChangeEvent"
          @checkbox-all="selectChangeEvent"
          @cell-click="cellClickEvent"
          @sort-change="sortChangeEvent"
          @scroll="tableScroll"
          :data="tableData"
          :sort-config="{
            orders: ['asc', 'desc'],
            trigger: 'cell',
            iconAsc: 'iconfont icon-32pxshengxu icon',
            iconDesc: 'iconfont icon-32pxjiangxu icon'
          }"
          show-overflow
          :filter-config="{
            remote: true,
            iconNone: 'iconfont icon-shaixuan icon',
            iconMatch: 'iconfont icon-shaixuan icon filter-product-btn'
          }"
          show-footer
          :footer-method="footerMethod"
          @filter-change="filterChangeEvent"
        >
          <vxe-column align="center" fixed="left" type="checkbox" width="80" show-header-overflow> </vxe-column>
          <vxe-column
            v-for="record in copyColumns"
            :key="record.dataIndex"
            :field="record.dataIndex"
            :title="record.title"
            :minWidth="record.width"
            :filters="
              record.dataIndex == 'type'
                ? [
                    { label: '全职', value: '全职' },
                    { label: '兼职', value: '兼职' },
                    { label: '意向全职', value: '意向全职' },
                    { label: '实习', value: '实习' }
                  ]
                : null
            "
            :sortable="record.dataIndex == 'beautifulSentenceCount' || record.dataIndex == 'finishedTaskCount' || record.dataIndex == 'likeRate'"
            :fixed="record.dataIndex == 'time' ? 'left' : ''"
            :tree-node="record.dataIndex == 'time'"
          >
            <template v-if="record.dataIndex == 'beautifulSentenceCount'" v-slot="{ row }">
              <span>{{ row.beautifulSentenceCount ? (row.beautifulSentenceCount - 0).toFixed(0) :row.beautifulSentenceCount }}</span>
            </template>
            <template v-else-if="record.dataIndex == 'finishedTaskCount'" v-slot="{ row }">
              <span>{{ row.finishedTaskCount ? (row.finishedTaskCount - 0).toFixed(0) : row.finishedTaskCount }}</span>
            </template>
            <template v-else-if="record.dataIndex == 'likeRate'" v-slot="{ row }">
              <span>{{ row.likeRate }}</span>
            </template>
          </vxe-column>
        </vxe-table>
      </a-dropdown>
    </a-spin>
  </div>
</template>

<script>
import { getAction } from '../../../api/manage'
export default {
  props: {
    timeView: {
      type: String,
      default: 'MONTH'
    },
    groupType: {
      type: String,
      default: 'POSITION'
    },
    departCode: {
      type: String,
      default: ''
    },
    positionId: {
      type: String,
      default: ''
    },
    selectUser: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: 'calc(100vh - 355px)'
    }
  },
  data() {
    return {
      rightVisible: false,
      tableLoading: false,
      selectRows: [],
      tasksTotal: 0,
      columns: [
        {
          title: '日期',
          dataIndex: 'time',
          width: 180,
          sorter: true,
          isNum: false,
          total: '统计',
          checked: true
        },
        {
          title: '对象',
          dataIndex: 'position',
          width: 120,
          sorter: true,
          isNum: false,
          total: '统计',
          checked: true
        },
        {
          title: '数量',
          dataIndex: 'beautifulSentenceCount',
          width: 120,
          sorter: true,
          isNum: true,
          total: '统计',
          checked: true
        },
        {
          title: '完成话数',
          dataIndex: 'finishedTaskCount',
          width: 120,
          sorter: true,
          isNum: true,
          total: '统计',
          checked: true
        },
        {
          title: '点赞率',
          dataIndex: 'likeRate',
          width: 120,
          sorter: true,
          isNum: true,
          total: '统计',
          checked: true
        },
      ],
      copyColumns: [
        {
          title: '日期',
          dataIndex: 'time',
          width: 180,
          sorter: true,
          isNum: false,
          total: '统计',
          checked: true
        },
        {
          title: '对象',
          dataIndex: 'position',
          width: 120,
          sorter: true,
          isNum: false,
          total: '统计',
          checked: true
        },
        {
          title: '数量',
          dataIndex: 'beautifulSentenceCount',
          width: 120,
          sorter: true,
          isNum: true,
          total: '统计',
          checked: true
        },
        {
          title: '完成话数',
          dataIndex: 'finishedTaskCount',
          width: 120,
          sorter: true,
          isNum: true,
          total: '统计',
          checked: true
        },
        {
          title: '点赞率',
          dataIndex: 'likeRate',
          width: 120,
          sorter: true,
          isNum: true,
          total: '统计',
          checked: true
        },
      ],
      tableData: [],
      treeConfig: {
        lazy: true,
        hasChild: 'hasChild',
        rowField: 'id',
        loadMethod: this.loadChildrenMethod,
        transform: true
      },
      scrollTop: 0,
      color: '',
      colorBack: '',
      filterList: [],
      propertys: []
    }
  },
  created() {},
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color,
        '--back-color': this.colorBack,
        '--table-height': this.height
      }
    }
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.colorBack = rule.style.backgroundColor
            localStorage.setItem(
              'color',
              JSON.stringify({ hoverColor: this.colorBack, color: this.$store.state.app.color })
            )
          }
        }
      }
    }, 50)
  },
  methods: {
    sortChangeEvent(e) {
      this.tableData.forEach(item => {
        item.children?.forEach(i => {
          if (i.children && i.children.length) {
              if (e.order == 'asc') {
                i.children = i.children?.sort((a, b) => {
                  return b[e.property] - a[e.property]
                })
              } else if (e.order == 'desc') {
                i.children = i.children?.sort((a, b) => {
                  return a[e.property] - b[e.property]
                })
              }
          }
        })
      })
      this.$refs.xTree.updateData()
    },
    meanNum(list, field) {
      list = this.selectRows.filter(item => !item.hasChild)
      console.log(list, field)
      if (field == 'totalScore') {
        let count = 0
        list
          .filter(item => item.totalScore)
          .forEach(item => {
            count += Number(item.totalScore)
          })
        return count || 0
      } else if (field == 'calcUnits') {
        let text = []
        let count = [
          { type: 'EACH', value: 0 },
          { type: 'CHAPTER', value: 0 },
          { type: 'VOLUME', value: 0 },
          { type: 'PAGE', value: 0 },
          { type: 'WORD', value: 0 },
          { type: 'THOUSAND', value: 0 },
          { type: 'GRID', value: 0 },
          { type: 'PERCENT', value: 0 }
        ]
        list
          .filter(item => item.calcUnits)
          .forEach(item => {
            item.calcUnits.forEach(i => {
              count.forEach(c => {
                if (c.type == i.type) {
                  c.value += i.value - 0
                }
              })
            })
          })
        return this.getCalcUnits(count)
      } else if (field == 'delayNum') {
        let count = 0
        list
          .filter(item => item.factor)
          .forEach(item => {
            count += Number(item.factor)
          })
        return count.toFixed(0) + '%' || 0
      } else {
        let count = 0
        list
          .filter(item => item[field])
          .forEach(item => {
            count += Number(item[field])
          })
        return count.toFixed(0) || 0
      }
    },
    allNum(list, field) {
      return list.length
    },
    sumNum(list, field) {
      list = this.selectRows.filter(item => !item.hasChild)
      if(!list.length) return 0
      if (field == 'totalScore') {
        let count = 0
        list
          .filter(item => item.totalScore)
          .forEach(item => {
            count += Number(item.totalScore)
          })
        let num = count / list.length
        return num.toFixed(0) || 0
      } else if (field == 'calcUnits') {
        let text = []
        let count = [
          { type: 'EACH', value: 0 },
          { type: 'CHAPTER', value: 0 },
          { type: 'VOLUME', value: 0 },
          { type: 'PAGE', value: 0 },
          { type: 'WORD', value: 0 },
          { type: 'THOUSAND', value: 0 },
          { type: 'GRID', value: 0 },
          { type: 'PERCENT', value: 0 }
        ]
        list
          .filter(item => item.calcUnits)
          .forEach(item => {
            item.calcUnits.forEach(i => {
              count.forEach(c => {
                if (c.type == i.type) {
                  c.value += i.value - 0
                }
              })
            })
          })
        count.forEach(item => {
          if (item.value) {
            item.value = item.value / list.length
            item.value = item.value.toFixed(0) || 0
          }
        })
        return this.getCalcUnits(count)
      } else if (field == 'delayNum') {
        let count = 0
        list
          .filter(item => item.factor)
          .forEach(item => {
            count += Number(item.factor)
          })
        return count.toFixed(0) + '%' || 0
      } else {
        let count = 0
        list
          .filter(item => item[field])
          .forEach(item => {
            count += Number(item[field])
          })
        let num = count / list.length
        return num.toFixed(0) || 0
      }
    },
    compute(methodName, index, menuIndex, menuText) {
      /* this.property='weekTaskCount' */
      this.methodName = methodName
      if (
        !this.propertys.some(item => {
          return item == index
        })
      ) {
        this.propertys.push(index)
      }
      this.$nextTick(() => {
        this.$refs.xTree.updateFooter() //手动更新表尾
        setTimeout(() => {
          this.$refs.xTree.setCheckboxRow(this.selectRows, true)
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, i) => {
              try {
                if (i == menuIndex) {
                  this.$refs['statistics-' + i][0].$el.innerText = menuText + '：' + this[methodName]([], index)
                }
                if (this.$refs['statistics-' + i][0].$el.innerText != '统计' && i == menuIndex) {
                  item.childNodes[0].childNodes[0].innerText = ''
                  item.childNodes[0].childNodes[0].style.opacity = '1'
                }
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + i][0].$el)
              } catch (err) {}
            })
        }, 0)
      })
    },
    async filterChangeEvent({ column, $event, values, filters }) {
      if ($event.target.innerText == '筛选') {
        this.filterList = values
        this.getTableData()
      } else {
        this.filterList = []
        this.getTableData()
      }
    },
    selectChangeEvent() {
      const records = this.$refs.xTree.getCheckboxRecords()
      this.$emit('setRowkeys', records)
      this.selectRows = records
      this.$refs.xTree.updateFooter() //手动更新表尾
    },
    cellClickEvent(e) {
      if (!e._columnIndex) return
      if (e.row.hasChild) {
        if (e.row.expend) {
          e.row.expend = false
          this.$refs.xTree.setTreeExpand(e.row, false)
        } else {
          e.row.expend = true
          this.$refs.xTree.setTreeExpand(e.row, true)
        }
        this.$refs.xTree.updateData()
        return
      }
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (this.propertys.includes(column.property)) {
            return parseInt(this[this.methodName](data, column.property))
          }
          if (columnIndex == 0) {
            return '已选择：' + this.selectRows.filter(item => !item.hasChild).length + '项'
          }
          return ''
        })
      ]
    },
    async getTableData() {
      let openArr = []
      if (this.$refs.xTree) {
        openArr = this.$refs.xTree.getTreeExpandRecords()
      }
      this.tableLoading = true
      let params = {
        groupCycle: this.timeView,
        groupType: this.groupType,
        departCode: this.departCode,
        positionId: this.positionId,
        userId: this.selectUser
      }
      const res = await getAction('/summary/list/beautifulSentenceWeeks', params)
      if (res.code == 200) {
        this.tableData = res.data?.map(item => {
          return {
            time: item.year + '年',
            id: item.year,
            hasChild: true,
            isYear: true
          }
        })
        this.tableLoading = false
        if (openArr.length) {
          //保留展开状态
          openArr.forEach(row => {
            if (row.isYear) {
              setTimeout(() => {
                this.$refs.xTree.setTreeExpand(this.$refs.xTree.getRowById(row.id), true)
              }, 0)
            } else {
              setTimeout(() => {
                this.$refs.xTree.setTreeExpand(this.$refs.xTree.getRowById(row.id), true)
              }, 800)
            }
          })
        }
        if (
          this.columns.some(item => {
            return item.isNum
          })
        ) {
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, index) => {
              if (item.childNodes[0]) {
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + index][0].$el)
              }
            })
        }
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    checkChange(menuIndex) {
      this.copyColumns = this.columns.filter(item => {
        return item.checked
      })
      Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
        .filter((item, index) => {
          return index != 0
        })
        .forEach((item, i) => {
          try {
            if (i == menuIndex) {
              if (this.columns[i].checked) {
                item.style.display = 'table-cell'
              } else {
                item.style.display = 'none'
              }
            }
          } catch (err) {}
        })
    },
    toggleExpandChangeEvent({ row, expanded }) {
      this.$refs.xTree.toggleTreeExpand(row)
      this.$refs.xTree.updateData()
      return
    },
    async loadChildrenMethod({ row }) {
      let arr = []
      if (this.timeView == 'MONTH') {
        if (row.isYear && !row.isMonth) {
          let params = {
            year: row.id,
            groupCycle: this.timeView,
            groupType: this.groupType,
            departCode: this.departCode,
            positionId: this.positionId,
            userId: this.selectUser
          }
          const res = await getAction('/summary/list/beautifulSentenceMonth', params)
          arr = res.data
            ?.find(item => item.year == row.id)
            .months?.map(item => {
              return {
                time: item + '月',
                id: row.id + '-' + item,
                hasChild: true,
                isYear: false,
                isMonth: true
              }
            })
          return arr
        }
        if (!row.isYear && row.isMonth) {
          let params = {
            month: row.id.split('-')[1],
            year: row.id.split('-')[0],
            groupCycle: this.timeView,
            groupType: this.groupType,
            departCode: this.departCode,
            positionId: this.positionId,
            userId: this.selectUser,
            current: 1,
            size: 50
          }
          const res = await getAction('/summary/list/beautifulSentence', params)
          arr = res.data?.map(item => {
            let position = ''
            let id = ''
            /*             if (this.groupType == 'POSITION' && item.position) {
              position = item.position.v || '无职位'
              id = row.id + '-' + item.position.k
            } else if (this.groupType == 'USER' && item.user && item.user.realname) {
              position = item.user.realname || ''
              id = row.id + '-' + item.user.id
            } */
            position = item.user.realname || ''
            id = row.id + '-' + item.user.id
            return {
              ...item.beautifulSentence,
              position,
              positionName: item.depart.v + '-' + (item.position && item.position.name),
              id: id + '-' + item.position.id + '-' + item.depart.k,
              hasChild: false,
              isYear: false,
              isMonth: false
            }
          })
          if (this.filterList.length) {
            arr = arr.filter(item => this.filterList?.some(i => item.type == i))
          }
          return arr
        }
      } else if (this.timeView == 'WEEK') {
        if (row.isYear && !row.isWeek) {
          let params = {
            year: row.id,
            groupCycle: this.timeView,
            groupType: this.groupType,
            departCode: this.departCode,
            positionId: this.positionId,
            userId: this.selectUser
          }
          const res = await getAction('/summary/list/beautifulSentenceWeeks', params)
          arr = res.data
            ?.find(item => item.year == row.id)
            .weeks?.map(item => {
              return {
                time: item.left + '-' + item.right,
                id: row.id + '-' + item.week,
                hasChild: true,
                isYear: false,
                isWeek: true
              }
            })
          return arr
        }
        if (!row.isYear && row.isWeek) {
          let params = {
            week: row.id.split('-')[1],
            year: row.id.split('-')[0],
            groupCycle: this.timeView,
            groupType: this.groupType,
            departCode: this.departCode,
            positionId: this.positionId,
            userId: this.selectUser,
            current: 1,
            size: 50
          }
          const res = await getAction('/summary/list/beautifulSentence', params)
          arr = res.data?.map(item => {
            let position = ''
            let id = ''
            /*             if (this.groupType == 'POSITION' && item.position) {
              position = item.position.v || '无职位'
              id = row.id + '-' + item.position.k
            } else if (this.groupType == 'USER' && item.user && item.user.realname) {
              position = item.user.realname || ''
              id = row.id + '-' + item.user.id
            } */
            position = item.user.realname || ''
            id = row.id + '-' + item.user.id
            return {
              ...item.beautifulSentence,
              position,
              positionName: item.depart.v + '-' + (item.position && item.position.name),
              id: id + '-' + item.position.id + '-' + item.depart.k,
              hasChild: false,
              isYear: false,
              isWeek: false
            }
          })
          if (this.filterList.length) {
            arr = arr.filter(item => this.filterList?.some(i => item.type == i))
          }
          return arr
        }
      } else if (this.timeView == 'YEAR') {
        let params = {
          year: row.id,
          groupCycle: this.timeView,
          groupType: this.groupType,
          departCode: this.departCode,
          positionId: this.positionId,
          userId: this.selectUser,
          current: 1,
          size: 50
        }
        const res = await getAction('/summary/list/beautifulSentence', params)
        arr = res.data?.map(item => {
          let position = ''
          let id = ''
          if (this.groupType == 'POSITION' && item.position) {
            position = item.position.v || '无职位'
            id = row.id + '-' + item.position.k
          } else if (this.groupType == 'USER' && item.user && item.user.realname) {
            position = item.user.realname || ''
            id = row.id + '-' + item.user.id
          }
          return {
            ...item.beautifulSentence,
            position,
            positionName: item.position && item.position.name,
            id,
            isYear: false,
            isWeek: false
          }
        })
        if (this.filterList.length) {
          arr = arr.filter(item => this.filterList?.some(i => item.type == i))
        }
        return arr
      }
    },
    tableScroll(e) {
      if (e.scrollTop > 10) {
        this.scrollTop = e.scrollTop
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .vxe-table--body-wrapper {
  max-height: var(--table-height) !important;
  min-height: 200px;
  overflow-y: auto;
}
/deep/ .vxe-table .vxe-sort--asc-btn {
  left: 0.35em !important;
  color: rgba(0, 0, 0, 0.65) !important;
  /* top:-0.45em !important; */
  top: 0 !important;
}
/deep/ .vxe-table .vxe-sort--desc-btn {
  left: -0.15em !important;
  color: rgba(0, 0, 0, 0.65) !important;
  /* top:-0.45em !important; */
  top: 0 !important;
}
/deep/ .vxe-table .vxe-sort--desc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .iconfont {
  font-weight: 700 !important;
  font-size: 12px !important;
}
/deep/ .vxe-table--render-default .vxe-table--fixed-right-wrapper {
  box-shadow: none !important;
}
.statistics {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
  &:hover {
    background: var(--back-color);
  }
}
.icon-list {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
  position: absolute;
  right: 0;
  top: 12px;
  i {
    &:hover {
      cursor: pointer;
    }
  }
}
.statistics-show {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
}
.statistics-menu-list {
  opacity: 0;
  height: 0;
}
/deep/ .vxe-footer--row {
  .vxe-cell--item {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: start;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  .vxe-footer--column {
    &:first-child {
      .vxe-cell--item {
        opacity: 1;
        min-width: 250px;
      }
    }
  }
}
/deep/ .vxe-cell--sort {
  font-size:12px !important;
  width: 1em !important;
  height: 1.35em !important;
  margin-right: 7px;
}
/deep/ .vxe-cell--filter {
  font-size: 12px !important;
  margin-top: 2px;
}
/deep/ .vxe-filter--btn {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  position: relative !important;
  top: -2px !important;
}
/deep/ .vxe-header--row {
  .vxe-cell {
    display: flex;
    align-items: center;
    .vxe-cell--title {
      flex: 1;
    }
  }
}
/deep/ .is--indeterminate .vxe-checkbox--icon::before {
  background-color: #fff !important;
}
/deep/ .vxe-checkbox--icon:hover {
  &::before {
    border-color: var(--theme-color) !important;
  }
}
/deep/ .is--checked .vxe-icon-checkbox-checked {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon {
  font-weight: 400 !important;
}
/deep/ .vxe-table--render-default .vxe-body--row.row--current {
  background-color: var(--back-color) !important;
}
/deep/ .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--border-line {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-top-width: 0 !important;
}
/deep/ .is--filter-active .vxe-cell--filter .vxe-filter--btn {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .is--checked {
  color: var(--theme-color) !important;
}
/deep/ .is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-option:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer button:hover {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer {
  display: flex;
  justify-content: space-evenly;
  button {
    &:hover {
      color: var(--theme-color);
    }
  }
}
/deep/ .vxe-table--filter-wrapper {
  z-index: 9999;
}
/deep/ .is--indeterminate.vxe-table--filter-option {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--body-wrapper {
  .vxe-body--x-space {
    display: none;
  }
}
/deep/ .vxe-table--render-default .fixed--hidden {
  visibility: unset !important;
}
/deep/ .vxe-table--fixed-wrapper .vxe-body--row:last-child .vxe-cell {
  height: auto !important;
}
.vxe-table--fixed-left-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/deep/ .vxe-body--column {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-table--footer-wrapper {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-header--row {
  background-color: #f8f8f9;
}
/deep/ .vxe-header--row .vxe-cell {
  font-weight: 400 !important;
}
/deep/ .row--hover {
  background-color: var(--back-color) !important;
}
</style>
