var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.backCss },
    [
      _c(
        "div",
        { staticClass: "statistics-menu-list" },
        [
          _vm._l(_vm.copyColumns, function (item, index) {
            return [
              _c(
                "a-dropdown",
                {
                  key: index,
                  ref: "statistics-" + index,
                  refInFor: true,
                  style: item.isNum ? "" : "display: none;",
                  attrs: { trigger: ["click"], placement: "topCenter" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "statistics" },
                    [
                      _vm._v("统计"),
                      _c("a-icon", { attrs: { type: "caret-up" } }),
                    ],
                    1
                  ),
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      item.isNum
                        ? _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.compute(
                                    "meanNum",
                                    item.dataIndex,
                                    index,
                                    "求和"
                                  )
                                },
                              },
                            },
                            [_vm._v("\n            求和\n          ")]
                          )
                        : _vm._e(),
                      item.isNum
                        ? _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.compute(
                                    "sumNum",
                                    item.dataIndex,
                                    index,
                                    "平均"
                                  )
                                },
                              },
                            },
                            [_vm._v("\n            平均\n          ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.tableLoading } },
        [
          _c(
            "a-dropdown",
            {
              attrs: { placement: "bottomRight", trigger: ["contextmenu"] },
              model: {
                value: _vm.rightVisible,
                callback: function ($$v) {
                  _vm.rightVisible = $$v
                },
                expression: "rightVisible",
              },
            },
            [
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                _vm._l(_vm.columns, function (item, index) {
                  return _c(
                    "a-menu-item",
                    { key: item.dataIndex },
                    [
                      _c(
                        "a-checkbox",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.checkChange(index)
                            },
                          },
                          model: {
                            value: item.checked,
                            callback: function ($$v) {
                              _vm.$set(item, "checked", $$v)
                            },
                            expression: "item.checked",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "vxe-table",
                {
                  ref: "xTree",
                  attrs: {
                    border: "none",
                    "row-config": {
                      isCurrent: true,
                      isHover: true,
                      keyField: "id",
                    },
                    "column-config": { resizable: true },
                    "tree-config": _vm.treeConfig,
                    data: _vm.tableData,
                    "sort-config": {
                      orders: ["asc", "desc"],
                      trigger: "cell",
                      iconAsc: "iconfont icon-32pxshengxu icon",
                      iconDesc: "iconfont icon-32pxjiangxu icon",
                    },
                    "show-overflow": "",
                    "filter-config": {
                      remote: true,
                      iconNone: "iconfont icon-shaixuan icon",
                      iconMatch:
                        "iconfont icon-shaixuan icon filter-product-btn",
                    },
                    "show-footer": "",
                    "footer-method": _vm.footerMethod,
                  },
                  on: {
                    "toggle-tree-expand": _vm.toggleExpandChangeEvent,
                    "checkbox-change": _vm.selectChangeEvent,
                    "checkbox-all": _vm.selectChangeEvent,
                    "cell-click": _vm.cellClickEvent,
                    "sort-change": _vm.sortChangeEvent,
                    scroll: _vm.tableScroll,
                    "filter-change": _vm.filterChangeEvent,
                  },
                },
                [
                  _c("vxe-column", {
                    attrs: {
                      align: "center",
                      fixed: "left",
                      type: "checkbox",
                      width: "80",
                      "show-header-overflow": "",
                    },
                  }),
                  _vm._l(_vm.copyColumns, function (record) {
                    return _c("vxe-column", {
                      key: record.dataIndex,
                      attrs: {
                        field: record.dataIndex,
                        title: record.title,
                        minWidth: record.width,
                        filters:
                          record.dataIndex == "type"
                            ? [
                                { label: "全职", value: "全职" },
                                { label: "兼职", value: "兼职" },
                                { label: "意向全职", value: "意向全职" },
                                { label: "实习", value: "实习" },
                              ]
                            : null,
                        sortable:
                          record.dataIndex == "beautifulSentenceCount" ||
                          record.dataIndex == "finishedTaskCount" ||
                          record.dataIndex == "likeRate",
                        fixed: record.dataIndex == "time" ? "left" : "",
                        "tree-node": record.dataIndex == "time",
                      },
                      scopedSlots: _vm._u(
                        [
                          record.dataIndex == "beautifulSentenceCount"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.beautifulSentenceCount
                                            ? (
                                                row.beautifulSentenceCount - 0
                                              ).toFixed(0)
                                            : row.beautifulSentenceCount
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              }
                            : record.dataIndex == "finishedTaskCount"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.finishedTaskCount
                                            ? (
                                                row.finishedTaskCount - 0
                                              ).toFixed(0)
                                            : row.finishedTaskCount
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              }
                            : record.dataIndex == "likeRate"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [_vm._v(_vm._s(row.likeRate))]),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }